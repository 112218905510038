import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchMe(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/me', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/user', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchRoleOptions(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/role/options')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchRoles(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/role/options')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/user/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/user', userData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateUser(ctx, { userData, id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/user/${id}`, userData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/user/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchStats(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/dashbord/stats')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
